<template>
  <div class="lazy-scroll">
    <div class="wrapper-terms" style="text-align: center">
      <h4 style="margin-top: 10px; margin-bottom: 10px; font-weight: bold">
        {{ lang[this.appLang].allow_company_management }}
      </h4>

      <img
        src="https://cdn.tapni.co/images/company-mockup.svg"
        class="fa responsive-image"
        style="width: 60%"
      />

      <h5 style="margin-top: 20px; margin-bottom: 20px" v-if="validLink">
        <span style="text-decoration: underline">{{ companyName }}</span>
        {{ lang[this.appLang].company_join_request }}
      </h5>

      <h5
        style="color: red; margin-bottom: 10px; font-size: 13px"
        v-if="!requestExpired && validLink"
      >
        {{ lang[this.appLang].account_ownership_transfer }}
      </h5>
      <h5
        style="margin-top: 30px; margin-bottom: 10px; font-size: 13px"
        v-if="requestExpired && validLink"
      >
        <b>{{ lang[this.appLang].request_expired }}</b
        ><br />{{ lang[this.appLang].request_expiration_desc }}
      </h5>
      <h5
        style="margin-top: 30px; margin-bottom: 10px; font-size: 13px"
        v-if="!validLink"
      >
        <h4>{{ lang[this.appLang].invitation_link_not_valid }}</h4>
        <br />{{ lang[this.appLang].invitation_link_not_valid2 }}
      </h5>

      <button
        type="submit"
        class="button white-button half-top edit-shadow"
        style="margin: 0 auto; width: 65%"
        v-if="!requestExpired && validLink"
        @click="acceptInvitation"
      >
        {{ lang[this.appLang].accept_request }} <br />
      </button>

      <button
        type="submit"
        class="button white-button half-top edit-shadow"
        style="margin: 0 auto; width: 65%"
        @click="$router.push('/')"
      >
        {{ lang[this.appLang].close }}
      </button>

      <p
        class="close-text center-text half-top color-black"
        style="font-size: 12px"
        v-if="!requestExpired && validLink"
      >
        {{ lang[this.appLang].terms_by_submitting }} <br />
        <a class="" href="https://tapni.co/policies/terms-of-service">{{
          lang[this.appLang].terms_of_service
        }}</a>
        {{ lang[this.appLang].and }}
        <a href="https://tapni.co/policies/privacy-policy">{{
          lang[this.appLang].privacy_policy
        }}</a>
      </p>

      <!--<p>For added security, this link will only work for 14 days after it was sent. If the link expires, ask your company management to send a new invite. .</p> -->
    </div>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
import { mapActions } from "vuex";

export default {
  name: "CompanyInvitation",
  data() {
    return {
      invitationCode: null,
      companyName: "",
      requestExpired: false,
      validLink: true,
    };
  },
  async mounted() {
    this.$store.commit("setInitialize", true);
    this.$store.commit("setLoading", false);
    try {
      let decoded = jwt_decode(this.$route.query.ic);
      this.companyName = decoded.companyName;
      if (Math.round(Date.now() / 1000) > decoded.exp)
        this.requestExpired = true;
    } catch (err) {
      this.validLink = false;
    }
  },
  methods: {
    ...mapActions(["acceptCompanyInvitation"]),
    async acceptInvitation() {
      const response = await this.acceptCompanyInvitation(this.$route.query.ic);
      if (response.data.success) {
        this.successSnack(this.lang[this.appLang].terms_by_submitting);
        return this.$router.push("/");
      }
    },
  },
};
</script>

<style>
</style>
